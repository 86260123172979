import { NavbarCTADef } from "@crafthunt-ui/NavbarCTA/NavbarCTA";
import { AuthBottomNavigation } from "features/auth/components/AuthBottomNavigation/AuthBottomNavigation";
import { AuthNavigation } from "features/auth/components/AuthNavigation/AuthNavigation";
import { useAuth } from "features/auth/context/AuthContext";
import { TranslatedSlugDef } from "features/translations/types/translation.types";
import { useHasHydrated } from "hooks/useHasHydrated";
import { StoryblokSettingsDef } from "types/storyblok.types";
import { DefaultNavigation } from "./components/DefaultNavigation/DefaultNavigation";

type NavigationProps = {
  locale: string;
  settings?: StoryblokSettingsDef;
  translatedSlugs?: TranslatedSlugDef[];
  hideNav?: boolean;
  hideAuthNav?: boolean;
  customNavbarAction?: NavbarCTADef[];
};

const Navigation = ({
  locale,
  settings,
  translatedSlugs,
  hideNav,
  hideAuthNav,
  customNavbarAction,
}: NavigationProps) => {
  const hasHydrated = useHasHydrated();
  const { loading, isLoggedIn } = useAuth();

  if (hasHydrated && !hideAuthNav && (loading || isLoggedIn)) {
    return (
      <>
        <AuthNavigation settings={settings} />
        <AuthBottomNavigation />
      </>
    );
  }

  return (
    <DefaultNavigation
      locale={locale}
      settings={settings}
      translatedSlugs={translatedSlugs}
      hideNav={hideNav}
      hideAuthNav={hideAuthNav}
      customNavbarAction={customNavbarAction}
    />
  );
};

export default Navigation;
